import React, { useState } from "react";

import { graphql, useStaticQuery, Link } from "gatsby";

import { motion } from "framer-motion";

// Component imports
import Image from "gatsby-image";
import { Slide } from "react-awesome-reveal";

// Static assets
import { ReactComponent as DownCaret } from "../images/icons/down-caret.svg";
const Path = (props: any) => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        {...props}
    />
);

const Header = () => {
    const [isOpen, toggleOpen] = useState(false);

    const { glyph } = useStaticQuery(graphql`
        query {
            glyph: file(relativePath: { eq: "glyph.png" }) {
                childImageSharp {
                    fixed(width: 40, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    // Components

    interface LinkProps {
        children: string;
        url: string;
        external?: boolean;
    }

    const MobileLink = ({ children, url, external }: LinkProps) => {
        if (external) {
            return (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-3xl font-bold uppercase transition duration-300 transform ease-inOutQuint sm:text-4xl hover:text-purple-highlight focus:text-purple-highlight focus:outline-none"
                >
                    {children}
                </a>
            );
        } else {
            return (
                <Link
                    to={url}
                    className="text-3xl font-bold uppercase transition duration-300 transform ease-inOutQuint sm:text-4xl hover:text-purple-highlight focus:text-purple-highlight focus:outline-none"
                >
                    {children}
                </Link>
            );
        }
    };

    const DesktopLink = ({ children, url, external }: LinkProps) => {
        if (external) {
            return (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative text-sm font-black text-black uppercase transition-all duration-300 group ease-inOutQuint lg:text-sm focus:text-purple-highlight hover:text-purple-highlight focus:outline-none"
                >
                    {children}
                    {typeof window !== "undefined" &&
                    window.location.pathname === url ? (
                        <div className="absolute inset-x-0 bottom-0 inline-block w-full h-1 mx-auto transition-all duration-300 bg-purple-highlight translate-y-[27px] ease-inOutQuint transform-gpu"></div>
                    ) : (
                        <div className="absolute inset-x-0 bottom-0 inline-block w-0 h-1 mx-auto transition-all duration-300 bg-purple-highlight translate-y-[27px] ease-inOutQuint group-hover:w-full transform-gpu"></div>
                    )}
                </a>
            );
        } else {
            return (
                <Link
                    to={url}
                    className="relative text-sm font-black text-black uppercase transition-all duration-300 group ease-inOutQuint lg:text-sm focus:text-purple-highlight hover:text-purple-highlight focus:outline-none"
                >
                    {children}
                    {typeof window !== "undefined" &&
                    window.location.pathname === url ? (
                        <div className="absolute inset-x-0 bottom-0 inline-block w-full h-1 mx-auto transition-all duration-300 bg-purple-highlight translate-y-[27px] ease-inOutQuint transform"></div>
                    ) : (
                        <div className="absolute inset-x-0 bottom-0 inline-block w-0 h-1 mx-auto transition-all duration-300 bg-purple-highlight translate-y-[27px] ease-inOutQuint group-hover:w-full transform"></div>
                    )}
                </Link>
            );
        }
    };

    // const DropDownLink = ({ children, url }: LinkProps) => {
    //     return (
    //         <li className="w-full text-black group hover:text-purple-highlight focus:text-purple-highlight">
    //             <a
    //                 href={`https://galaxies.spacedelta.net/${url}`}
    //                 className="block w-full p-3 transition duration-300 lg:text-sm ease-inOutQuint"
    //             >
    //                 {children}
    //             </a>
    //         </li>
    //     );
    // };

    // const GalaxyDropdown = () => (
    //     <div className="group">
    //         <div className="relative flex items-center space-x-4 text-sm font-black text-black uppercase transition-all duration-300 cursor-pointer ease-inOutQuint lg:text-sm focus:text-purple-highlight hover:text-purple-highlight focus:outline-none">
    //             <span>Galaxy</span>
    //             <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="8.801"
    //                 height="6.739"
    //                 viewBox="0 0 8.801 6.739"
    //             >
    //                 <path
    //                     id="layer1"
    //                     d="M20,26l3.452,4.008L26.907,26"
    //                     transform="translate(-19.053 -25.184)"
    //                     fill="none"
    //                     stroke="currentColor"
    //                     stroke-miterlimit="10"
    //                     stroke-width="2.5"
    //                 />
    //             </svg>
    //             <div className="absolute top-0 right-0 w-40 h-0 overflow-hidden transition-all duration-300 transform translate-y-10 bg-white translate-x-7 group-hover:h-48 group-focus:h-48 ease-inOutQuint">
    //                 <ul className="flex flex-col items-center h-full justify-evenly">
    //                     <DropDownLink url="/">Home</DropDownLink>
    //                     <DropDownLink url="/species">Species</DropDownLink>
    //                     <DropDownLink url="/regions">Regions</DropDownLink>
    //                     <DropDownLink url="/runes">Runes</DropDownLink>
    //                 </ul>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <header className="sticky inset-0 z-50 bg-white">
            <nav className="container flex items-center justify-between px-6 py-3 mx-auto md:px-8">
                {/* Navbar Left */}
                <div className="z-30">
                    <Link to="/">
                        <span className="sr-only">Home</span>
                        <Image
                            className="transform translate-y-1"
                            fixed={glyph.childImageSharp.fixed}
                            alt="SpaceDelta Logo"
                        />
                    </Link>
                </div>

                {/* Desktop Navbar */}
                <div className="hidden lg:flex lg:items-center lg:justify-between lg:flex-1 lg:pl-16">
                    {/* Navbar Left */}
                    <ul className="flex items-center -mx-6 text-center list-none lg:gap-8">
                        <li className="px-4 lg:px-6">
                            <DesktopLink url="/">Home</DesktopLink>
                        </li>

                        <li className="px-4 lg:px-6">
                            <DesktopLink url="/news">News</DesktopLink>
                        </li>
                        <li className="px-4 lg:px-6">
                            <DesktopLink
                                url="https://www.spacedelta.shop"
                                external
                            >
                                Shop
                            </DesktopLink>
                        </li>
                        <li className="px-4 lg:px-6">
                            <DesktopLink url="/vote">Vote</DesktopLink>
                        </li>
                        <li className="px-4 lg:px-6">
                            <DesktopLink url="/rules">Rules</DesktopLink>
                        </li>
                        <li className="px-4 lg:px-6">
                            <DesktopLink
                                url="https://discord.gg/spacedelta"
                                external
                            >
                                Discord
                            </DesktopLink>
                        </li>
                        <li className="px-4 lg:px-6">
                            <DesktopLink url="/jobs">Jobs</DesktopLink>
                        </li>
                        {/* <li className="px-4 lg:px-6">
                            <GalaxyDropdown />
                        </li> */}
                    </ul>
                </div>

                {/* Mobile Navbar Toggle */}
                <motion.button
                    initial={false}
                    animate={isOpen ? "open" : "closed"}
                    className="z-30 text-white duration-300 ease-in-out focus:outline-none lg:hidden"
                    onClick={() => {
                        toggleOpen(!isOpen);
                    }}
                >
                    <svg width="23" height="23" viewBox="0 0 23 23">
                        <Path
                            variants={{
                                closed: {
                                    d: "M 2 2.5 L 20 2.5",
                                    stroke: "hsl(0, 0%, 18%)",
                                },
                                open: {
                                    d: "M 3 16.5 L 17 2.5",
                                    stroke: "hsl(0, 0%, 100%)",
                                },
                            }}
                        />
                        <Path
                            d="M 2 9.423 L 20 9.423"
                            variants={{
                                closed: { opacity: 1 },
                                open: { opacity: 0 },
                            }}
                            transition={{ duration: 0.1 }}
                        />
                        <Path
                            variants={{
                                closed: {
                                    d: "M 2 16.346 L 20 16.346",
                                    stroke: "hsl(0, 0%, 18%)",
                                },
                                open: {
                                    d: "M 3 2.5 L 17 16.346",
                                    stroke: "hsl(0, 0%, 100%)",
                                },
                            }}
                        />
                    </svg>
                </motion.button>

                {/* Mobile Navbar */}
                <div
                    className="fixed top-0 bottom-0 z-20 flex flex-col justify-center w-screen min-h-screen p-6 overflow-hidden transition-all duration-500 bg-purple-900 lg:hidden ease-easeInOutQuint"
                    style={{ right: isOpen ? "0vw" : "-100vw" }}
                >
                    <ul className="container pt-8 mx-auto list-none">
                        <Slide
                            cascade
                            damping={0.1}
                            delay={500}
                            reverse={!isOpen}
                            className="grid gap-2"
                        >
                            <li>
                                <MobileLink
                                    url="https://spacedelta.net"
                                    external
                                >
                                    Home
                                </MobileLink>
                            </li>
                            <li>
                                <MobileLink
                                    url="https://spacedelta.net/news"
                                    external
                                >
                                    News
                                </MobileLink>
                            </li>
                            <li>
                                <MobileLink
                                    external
                                    url="https://www.spacedelta.shop"
                                >
                                    Store
                                </MobileLink>
                            </li>
                            <li>
                                <MobileLink
                                    url="https://spacedelta.net/vote"
                                    external
                                >
                                    Vote
                                </MobileLink>
                            </li>
                            <li>
                                <MobileLink
                                    url="https://spacedelta.net/rules"
                                    external
                                >
                                    Rules
                                </MobileLink>
                            </li>
                            <li>
                                <MobileLink
                                    url="https://discord.gg/spacedelta"
                                    external
                                >
                                    Discord
                                </MobileLink>
                            </li>
                            <li>
                                <MobileLink url="/Jobs">Jobs</MobileLink>
                            </li>
                            {/* <li>
                                <h3 className="text-lg tracking-widest uppercase">
                                    Galaxy
                                </h3>
                                <div>
                                    <ul>
                                        <li>
                                            <Link
                                                to="/"
                                                className="font-bold uppercase transition duration-300 ease-inOutQuint hover:text-purple-highlight focus:text-purple-highlight focus:outline-none"
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/species"
                                                className="font-bold uppercase transition duration-300 ease-inOutQuint hover:text-purple-highlight focus:text-purple-highlight focus:outline-none"
                                            >
                                                Species
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/regions"
                                                className="font-bold uppercase transition duration-300 ease-inOutQuint hover:text-purple-highlight focus:text-purple-highlight focus:outline-none"
                                            >
                                                Regions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/runes"
                                                className="font-bold uppercase transition duration-300 ease-inOutQuint hover:text-purple-highlight focus:text-purple-highlight focus:outline-none"
                                            >
                                                Runes
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}
                        </Slide>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
