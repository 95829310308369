import React from "react";

// Component imports
import Footer from "./Footer";
import Header from "./Header";

interface Props {
    children: React.ReactNode;
    diffBackground?: boolean;
}

const Layout = ({ children, diffBackground }: Props) => {
    return (
        <div
            style={{ scrollBehavior: "smooth" }}
            className={` ${
                diffBackground ? "bg-gray-900" : "bg-background"
            } flex flex-col h-screen min-h-screen overflow-x-hidden leading-relaxed text-white text-gray-300 font-body`}
        >
            <Header />
            <main className="flex flex-col flex-1">{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
