import React from "react"

interface Props {
  children: string
  variant: string
  className?: string
  innerHtml?: React.ReactElement
}

const Text = ({ children, variant, className, innerHtml, ...props }: Props) => {
  switch (variant) {
    case "h2":
      return (
        <h2
          className={`font-medium  uppercase text-2xl lg:text-3xl ${
            className ? className : ""
          }`}
        >
          {children}
        </h2>
      )
    case "h3":
      return (
        <h3
          className={`font-semibold capitalize ${className ? className : ""}`}
        >
          {children}
        </h3>
      )

    case "p":
      return (
        <p className={`${className ? className : ""} tracking-wide`} {...props}>
          {children}
        </p>
      )
  }

  return null
}

export default Text
