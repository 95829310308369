import React from "react"

interface Props {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties | undefined
  verticalPadding?: boolean
}

const Section = ({
  children,
  className,
  style,
  verticalPadding = true,
}: Props) => {
  return (
    <section
      className={`${className ? className : ""} px-4 ${
        verticalPadding && "py-12 lg:py-12 xl:py-20 md-py-8"
      }  md:px-8  container mx-auto `}
      style={style}
    >
      {children}
    </section>
  )
}

export default Section
