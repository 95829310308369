import React from "react";

import { graphql, useStaticQuery, Link } from "gatsby";

// Component imports
import Text from "./Text";
import Image from "gatsby-image";

interface Props {}

const Footer = (props: Props) => {
    const { glyph } = useStaticQuery(graphql`
        query {
            glyph: file(relativePath: { eq: "glyph.png" }) {
                childImageSharp {
                    fixed(width: 40, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <footer className="bg-background">
            <div className="container p-6 mx-auto md:pt-10 xl:pt-18 md:px-8">
                {/* Footer Grid */}
                <div className="grid gap-8 md:grid-cols-3">
                    {/* Footer Left */}
                    <div>
                        <Text variant="h3" className="mb-5">
                            About Us
                        </Text>
                        <Text variant="p" className="text-sm opacity-30">
                            SpaceDelta, a multi-gamemode network set in the
                            aftermath of an intergalactic war. With exclusive,
                            custom content and immersive lore, our vision seeks
                            to create a fun, well-polished experience for all
                            our thrill-seeking players.
                        </Text>
                    </div>

                    {/* Footer Quick Nav */}
                    <div className="order-last md:order-none md:flex md:flex-col md:justify-between">
                        <div>
                            <ul className="flex items-center mb-3 justify-evenly">
                                <li>
                                    <Link
                                        to="/"
                                        className="text-sm font-bold text-gray-300 uppercase transition-all duration-300 ease-in-out focus:text-purple-600 hover:text-purple-600"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/vote"
                                        className="text-sm font-bold text-gray-300 uppercase transition-all duration-300 ease-in-out focus:text-purple-600 hover:text-purple-600"
                                    >
                                        Vote
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/rules"
                                        rel="noopener noreferrer"
                                        className="text-sm font-bold text-gray-300 uppercase transition-all duration-300 ease-in-out focus:text-purple-600 hover:text-purple-600"
                                    >
                                        Rules
                                    </Link>
                                </li>
                            </ul>
                            <Text
                                variant="p"
                                className="text-sm font-medium text-center opacity-30"
                            >
                                Quick Links
                            </Text>
                        </div>
                        <div className="flex justify-center mt-8 md:mt-0">
                            <Image
                                fixed={glyph.childImageSharp.fixed}
                                alt="SpaceDelta Logo"
                            />
                        </div>
                    </div>

                    {/* Footer Right */}
                    <div>
                        <Text variant="h3" className="mb-5">
                            Visit Our Store
                        </Text>
                        <Text variant="p" className="text-sm opacity-30">
                            Support the SpaceDelta network and community by
                            buying yourself some cool stuff.
                        </Text>
                        <a
                            href="https://www.spacedelta.shop"
                            rel="noopener noreferrer"
                            className="block w-full py-4 mt-5 text-sm font-semibold text-center uppercase transition-all duration-300 border-2 border-gray-500 ease-easeInOutQuint hover:bg-white hover:text-black hover:border-transparent focus:bg-white focus:font-semibold focus:border-transparent focus:outline-none active:outline-none"
                        >
                            Visit Store
                        </a>
                    </div>
                </div>
                {/* Copyright */}
                <div className="mt-5 border-t-2 border-gray-500 md:mt-12">
                    <Text
                        variant="p"
                        className="py-5 -mb-3 text-xs font-medium text-center opacity-25"
                    >
                        Copyright 2023 - SpaceDelta <br /> SpaceDelta is not
                        affiliated with Mojang.
                    </Text>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
